<template>
    <div class="container">    
        <br>
        <Wizard />
    </div>
</template>

<script>
import Wizard from "@/components/goal/Wizard"
import firebase from "firebase/app"
export default {
    components: {  Wizard },
    mounted(){
		firebase.analytics().logEvent('View page Target');
	},
    
}
</script>

<style>
    
</style>