<template>
    <div class="col-12">
        <div class="row">
            <div v-if="!modal" :class=" ColumnWidth()">
                <div class="form-group">
                    <label class="form-label">Código</label>
                    <input type="text" ref="code" class="form-control"  v-on:keyup.enter="keyupEnter('note')" name="example-text-input" v-model="code" >
                </div>
            </div>
            <div :class=" ColumnWidth()">
                <div class="form-group">
                    <label class="form-label">Nota</label>
                    <input type="number" ref="note" class="form-control"   v-on:keyup.enter="keyupEnter('topPrice')" name="example-text-input" v-model="note">
                </div>
            </div>
            <div :class=" ColumnWidth()">
                <div class="form-group">
                    <label class="form-label">{{(StockClass == 'rf' ? 'Valor' : 'Preço Teto')}}</label>
                    <div class="input-group">
                        <CoinControll />
                        <input type="number" ref="topPrice" class="form-control"  v-on:keyup.enter="keyupEnter('quantity')" name="example-text-input" v-model="topPrice" >
                    </div>
                </div>
            </div>
            <div :class=" ColumnWidth()">
                <div class="form-group">
                    <label class="form-label">Quantidade</label>
                    <input type="number" ref="quantity" class="form-control"  v-on:keyup.enter="keyupEnterDad('add-' + StockClass)" name="example-text-input"  v-model="quantity">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import CoinControll from "@/components/widgets/CoinControll"
export default {
    components: { CoinControll },
    props: {
        modal: { type: Boolean },
        StockClass: { type: String },
        keyupEnterDad: { type: Function },
    },
    computed:{
        code:{
            get: function() {  return this.$store.state.global.code },
            set: function(newValue) {this.$store.state.global.code = newValue} 
        },
        note:{
            get: function() {return this.$store.state.global.note },
            set: function(newValue) {this.$store.state.global.note = newValue} 
        },
        topPrice:{
            get: function() {return this.$store.state.global.topPrice},
            set: function(newValue) {this.$store.state.global.topPrice = newValue} 
        },
        quantity:{
            get: function() {return this.$store.state.global.quantity},
            set: function(newValue) {this.$store.state.global.quantity = newValue} 
        },
    },
    watch:{
        note(newVal, oldVal){
            if(newVal > 10000) this.note = oldVal
        }
    },
    methods:{
        ColumnWidth(){
            return 'col-lg-' + (this.modal ? '12' : '3')
        },
        keyupEnter(where){
            this.$refs[where].focus()
            this.$refs[where].select()
        },
    }
}
</script>

<style>

</style>