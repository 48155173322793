<template>
    <div>
        <div class="form-group">
            <label class="form-label">Valor do aporte</label>
            <div class="input-group">
                <CoinControll />
                <input
                    type="number"
                    class="form-control"
                    placeholder="Valor do aporte"
                    v-model="ValueBuy"
                    v-on:keyup.enter="Calculate"
                />
                <span class="input-group-append">
                    <button class="btn btn-cyan" type="button" @click="Calculate">Calcular</button>
                </span>
                <span v-if="Buy > 0 && !$isMobile()" class="input-group-append">
                    <button
                        class="btn btn-primary"
                        type="button"
                        @click="$bvModal.show('BuyInfo')"
                    >Inf. Aporte</button>
                </span>
            </div>
            <span v-if="Buy > 0 && $isMobile()">
                <button
                    class="btn btn-primary mt-2 btn-block"
                    type="button"
                    @click="$bvModal.show('BuyInfo')"
                >Inf. Aporte</button>
            </span>
        </div>
        <BuyInfo />
    </div>
</template>

<script>
import CoinControll from "@/components/widgets/CoinControll"
import BuyInfo from "@/components/rebalancing/BuyInfo"
import { mapGetters } from 'vuex'
export default {
    components: { CoinControll, BuyInfo },
    data() {
        return {
            // ValueBuy:1000,
            ValueCalc: 0,
            NewClass: [],
            Buy: 0,
            Coin: "",
            UseObjectivePercentage: false,
            NotBreakFunc: 0,
            NotBreakFuncValue: 0,
            PorcentCalc: 0.01,
            SumValueInvest: 0,
            MinValueStocks: 99999999,
            NewValue: 0,
            exitFor: 0,
            reset: false
        }
    },
    computed: {
        ...mapGetters('portfolio', ['CurrentPercentage', 'GetDecimalPlaces']),
        PremiumType() {
            return this.$store.state.portfolio.DataBase.settings.premium.type
        },
        ValueBuy: {
            get: function () { return this.$store.state.portfolio.ValueBuy },
            set: function (newValue) { this.$store.state.portfolio.ValueBuy = newValue }
        },
    },
    props: {
        DataBase: { type: Object, required: true },
        radioCoin: { type: String, required: true },
        // ValueBuy: { type: Number, required: true },
    },
    methods: {
        async Calculate() {
            if (isNaN(parseFloat(this.ValueBuy))) {
                this.ValueBuy = 1000
                return
            }

            await this.SetIniVariable()
            await this.DataBase.portfolio.stocks.sort((a, b) => {
                return (a.code.localeCompare(b.code))
            })

            await this.DataBase.portfolio.stocks.sort((a, b) => {
                return (this.CurrentPercentage(a.code) - a.percentageObjective) - (this.CurrentPercentage(b.code) - b.percentageObjective)
            })
            this.exitFor = 0
            setTimeout(async () => {
                await this.SetSupportPortfolio()

                if (this.$store.state.portfolio.PortfolioCalc.length == 0) {
                    this.$swal("Atenção", "Não encontramos nenhum ativo para realizar o rebalanceamento.", "warning")
                }

                await this.CalcNewPercentageClass()
                await this.CalcNewPercentageAssets()
                await this.CalcQuantityBuy()

                this.exitFor = 0
                if (this.ValueCalc > 0) {
                    this.NotBreakFunc = 0;
                    this.PorcentCalc = 0.03
                    for (let index = 0; index < 2; index++) {
                        for (let index2 = 0; index2 < this.$store.state.portfolio.PortfolioCalc.length; index2++) {
                            const element = this.$store.state.portfolio.PortfolioCalc[index2]

                            element.stopBug = false
                        }
                        this.exitFor += 1
                        await this.CalcQuantityBuy()
                        this.PorcentCalc = this.PorcentCalc * 2
                        this.NotBreakFunc = 0
                        if (this.exitFor < 10 && parseFloat(this.$store.state.portfolio.progress) < 99.99) index = 0

                    }
                }

                this.$store.state.portfolio.CallOrderByIni = new Date()
                this.$store.state.portfolio.calculating = false
            }, 100);
        },
        SetIniVariable() {
            return new Promise(resolve => {
                this.NotBreakFunc = 0
                this.$store.state.portfolio.calculating = true
                this.ValueCalc = parseFloat(this.ValueBuy)
                this.Buy = 0
                this.UseObjectivePercentage = false
                this.PorcentCalc = 0.01
                // this.Coin = this.radioCoin
                // if(!this.DataBase.settings.showAllCoins) 
                this.Coin = (this.DataBase.settings.usdCoin ? 'priceUSD' : 'priceBRL')
                this.$store.state.portfolio.progress = 0
                resolve(true)
            });
        },
        ChangeProgressBar() {
            return new Promise(resolve => {
                setTimeout(() => {
                    this.$store.state.portfolio.progress = parseFloat(100 - this.ValueCalc * 100 / this.ValueBuy).toFixed(2)
                    resolve(true)
                }, 0);
            });

        },
        async CalcQuantityBuy() {

            await this.CalcNewPercentageDiff()
            if (this.UseObjectivePercentage == false) await this.$store.state.portfolio.PortfolioCalc.sort((a, b) => { a.percentageDiff - b.percentageDiff })
            // if(this.UseObjectivePercentage == false) await this.$store.state.portfolio.PortfolioCalc.sort((a,b) => { (a.NewPercentage - a.NewPercentageObjective) - (b.NewPercentage - b.NewPercentageObjective)})
            await this.ChangeProgressBar();

            for (let index = 0; index < this.$store.state.portfolio.PortfolioCalc.length; index++) {
                const element = this.$store.state.portfolio.PortfolioCalc[index]

                await this.SetNoBreaking()
                if (await this.CheckCalc(element)) continue

                let DecimalPlaces = this.GetDecimalPlaces(element.class);
                let QuantityBuy = await this.CalcQuantity(element, DecimalPlaces)

                element.quantityBuy = parseFloat(element.quantityBuy + QuantityBuy).toFixed(DecimalPlaces)
                element.quantityBuy = parseFloat(element.quantityBuy)

                this.ValueCalc = parseFloat(this.ValueCalc) - parseFloat(QuantityBuy * element[this.Coin])
                this.Buy += (QuantityBuy * element[this.Coin])

                await this.ChangeProgressBar();
                // await this.CalcQuantityBuy()

                await this.CalcNewPercentageDiff()
                // await this.$store.state.portfolio.PortfolioCalc.sort((a,b) => {  a.percentageDiff - b.percentageDiff})

                // index = 0;
            }
            // console.log(this.ValueBuy, this.ValueCalc, this.MinValueStocks, this.NotBreakFunc, this.$store.state.portfolio.progress)

            if (this.$store.state.portfolio.progress < 100 && this.MinValueStocks < this.ValueCalc && this.NotBreakFunc < (this.$store.state.portfolio.PortfolioCalc.length * 10)) await this.CalcQuantityBuy()
        },
        async CalcQuantity(element, DecimalPlaces) {
            return new Promise(resolve => {
                // let Divider = 100 / (DecimalPlaces == 0 ? 2 : DecimalPlaces )
                // Divider = (this.SumValueInvest == 0 ? 100 : Divider)

                let Divider = 100
                let value = parseFloat(parseFloat(element.percentageDiff * this.ValueCalc / Divider).toFixed(2 + DecimalPlaces));

                if (DecimalPlaces > 0) {
                    let ValueReturn = parseFloat(value * (this.exitFor + 1) / element[this.Coin]).toFixed(DecimalPlaces)
                    if (element.class == 'rf') ValueReturn = parseFloat(value / element[this.Coin]).toFixed(DecimalPlaces)
                    //if(element.class == 'criptomoedas') ValueReturn = parseFloat(value * (this.exitFor + 10) / element[this.Coin]).toFixed(DecimalPlaces)
                    if (ValueReturn == 0) element.stopBug = true

                    resolve(parseFloat(ValueReturn))
                }

                resolve(Math.ceil(value / element[this.Coin]))
            });
        },
        CheckCalc(element) {
            return new Promise(resolve => {
                let DecimalPlaces = this.GetDecimalPlaces(element.class);
                let Divider = parseFloat("1" + '0'.repeat(DecimalPlaces))

                if (this.$store.state.portfolio.PortfolioCalc.length == 1) resolve(false)
                // console.log(element.code, element.percentageDiff);
                if (element.stopBug) resolve(true)
                if (this.NotBreakFunc > 10) resolve(true)
                if (parseFloat(element.percentageDiff) <= 0) resolve(true)


                if (this.ValueCalc <= parseFloat(parseFloat(element[this.Coin]) / Divider)) resolve(true)
                if (element.percentageDiff <= this.PorcentCalc) resolve(true)

                if (element.NewPercentageObjective - element.NewPercentage <= 0 && this.UseObjectivePercentage == true && this.SumValueInvest > 0) {
                    if (this.NotBreakFunc > this.$store.state.portfolio.PortfolioCalc.length && this.reset == false) {
                        this.NotBreakFunc = 0
                        this.reset = true
                    } else {
                        if (this.NewValue != this.ValueCalc) {
                            this.reset = false
                            this.NewValue = this.ValueCalc
                        }
                    }
                    resolve(!this.reset)

                    //resolve(false)
                }

                resolve(false)
            });
        },
        async SetNoBreaking() {
            return new Promise(resolve => {
                //Validação para não entrar e um loop infinito
                if (this.NotBreakFuncValue != this.$store.state.portfolio.progress) {
                    this.NotBreakFunc = 0
                    this.NotBreakFuncValue = this.$store.state.portfolio.progress
                } else {
                    this.NotBreakFunc += 1
                }
                resolve(true)
            });
        },
        async CalcNewPercentageDiff() {
            return new Promise(resolve => {
                let SumValueAssets = 0
                for (let index = 0; index < this.$store.state.portfolio.PortfolioCalc.length; index++) {
                    const element = this.$store.state.portfolio.PortfolioCalc[index]

                    //Somo tanto o valor investido quanto o valor que ele vai aportar nesse ativo para ter calc a % atual
                    SumValueAssets += (parseFloat(element[this.Coin]) * parseFloat(element.quantity)) + (parseFloat(element[this.Coin]) * parseFloat(element.quantityBuy))
                }

                for (let index = 0; index < this.$store.state.portfolio.PortfolioCalc.length; index++) {
                    const element = this.$store.state.portfolio.PortfolioCalc[index]

                    element.NewPercentage = ((parseFloat(element[this.Coin]) * parseFloat(element.quantity)) + (parseFloat(element[this.Coin]) * parseFloat(element.quantityBuy))) * 100 / SumValueAssets
                    element.percentageDiff = element.NewPercentageObjective - element.NewPercentage
                    // element.percentageDiff = element.NewPercentage * element.NewPercentageObjective / 100
                }

                //Verifica se preciso usar outra porcentagem pois a carteira já está balanceada
                this.UseObjectivePercentage = true
                for (let index = 0; index < this.$store.state.portfolio.PortfolioCalc.length; index++) {
                    const element = this.$store.state.portfolio.PortfolioCalc[index]

                    if (element.percentageDiff >= this.PorcentCalc && this.NotBreakFunc > (this.$store.state.portfolio.PortfolioCalc.length * 5)) this.UseObjectivePercentage = false
                }


                if (this.SumValueInvest == 0) this.UseObjectivePercentage = true
                //Troca a porcentagem do calc
                if (this.UseObjectivePercentage) {
                    for (let index = 0; index < this.$store.state.portfolio.PortfolioCalc.length; index++) {
                        const element = this.$store.state.portfolio.PortfolioCalc[index]

                        element.percentageDiff = parseFloat(element.percentageObjective)// - element.NewPercentage
                    }
                }

                resolve(true)
            });
        },

        async CalcNewPercentageAssets() {
            return new Promise(resolve => {
                for (let index = 0; index < this.NewClass.length; index++) {
                    let SumNote = 0
                    const elementClass = this.NewClass[index];
                    let PortfolioByClass = this.$store.state.portfolio.PortfolioCalc.filter(item => item.class == elementClass.code) //Pego todos os elementos desse class

                    //Somo o valor total das notas do ativos dessa class
                    for (let index = 0; index < PortfolioByClass.length; index++) {
                        const element = PortfolioByClass[index]
                        SumNote += parseFloat(element.note)
                    }

                    //Calculo a nova porcentagem objetiva de cada ativo
                    for (let index = 0; index < PortfolioByClass.length; index++) {
                        const element = PortfolioByClass[index]
                        let PercentageAssetsByClass = parseFloat(element.note) * 100 / SumNote
                        element.NewPercentageObjective = PercentageAssetsByClass * elementClass.objectivePercentage / 100
                    }
                }

                resolve(true)
            });
        },
        async CalcNewPercentageClass() {
            return new Promise(resolve => {
                this.NewClass = []
                let SumPercentegeAllClass = 0

                //Para calcular a nova porcentagem das classes preciso somar todas elas
                for (let index = 0; index < this.DataBase.portfolio.class.length; index++) {
                    const element = this.DataBase.portfolio.class[index]

                    let PortfolioByClass = this.$store.state.portfolio.PortfolioCalc.filter(item => item.class == element.code)

                    let CountIsBuy = PortfolioByClass.filter(item => item.isBuy == true).length > 0
                    if (!CountIsBuy) continue //element.isBuy = false

                    let CountPercentageObjective = PortfolioByClass.filter(item => item.percentageObjective > 0).length > 0
                    if (!CountPercentageObjective) element.isBuy = false

                    if (!element.isBuy) continue

                    SumPercentegeAllClass += parseFloat(element.objectivePercentage)
                }

                //Set em uma Var  o novo valor da objectivePercentage
                for (let index = 0; index < this.DataBase.portfolio.class.length; index++) {
                    const element = this.DataBase.portfolio.class[index]
                    if (!element.isBuy) continue

                    this.NewClass.push({
                        code: element.code,
                        objectivePercentage: (parseFloat(element.objectivePercentage) * 100 / SumPercentegeAllClass)
                    })

                }

                resolve(true)
            });
        },
        async SetSupportPortfolio() {
            return new Promise(resolve => {
                this.$store.state.portfolio.PortfolioCalc = []

                for (let index = 0; index < this.DataBase.portfolio.stocks.length; index++) {
                    const element = this.DataBase.portfolio.stocks[index]

                    //ZERO OS VAR QUE USO PARA CALC
                    element.quantityBuy = 0
                    element.percentageDiff = 0
                    element.NewPercentageObjective = 0
                    element.NewPercentage = 0
                    element.stopBug = false

                    if (element.class == 'rf' && element[this.Coin] == 0) {
                        element.priceBRL = 1
                        element.priceUSD = 1
                    }
                    // console.log(element.class == 'rf' , element[this.Coin] == 0);

                    let DecimalPlaces = this.GetDecimalPlaces(element.class);
                    let Divider = parseFloat("1" + '0'.repeat(DecimalPlaces))
                    if (parseFloat(parseFloat(element[this.Coin]) / Divider) > parseFloat(this.ValueBuy)) continue //Tenho que verificar as casas decimais de alguns ativos

                    if (this.PremiumType > 1) {
                        let topPrice = (this.DataBase.settings.usdCoin ? 'topPriceUSD' : 'topPriceBRL')
                        if (parseFloat(element[topPrice]) > 0 && parseFloat(element[this.Coin]) > parseFloat(element[topPrice])) continue //Tiros todos os ativos que estiverem acima do preço teto
                    }

                    if (parseFloat(element[this.Coin]) <= 0) continue //Tiro todos os ativos que estiverem como valor zerado
                    if (!element.isBuy) continue //Tiro os ativos que não quero comprar
                    if (element.percentageObjective <= 0) continue // Tiro os ativos que não tem porcentagem objetiva
                    let isBuyClass = this.DataBase.portfolio.class.filter(elementClass => elementClass.code == element.class)[0]
                    if (!isBuyClass.isBuy) continue // Tiro as categorias não selecionadas
                    this.$store.state.portfolio.PortfolioCalc.push(element)
                }
                this.SumValueInvest = 0
                for (let index = 0; index < this.$store.state.portfolio.PortfolioCalc.length; index++) {
                    const element = this.$store.state.portfolio.PortfolioCalc[index]
                    this.SumValueInvest += (parseFloat(element[this.Coin]) * parseFloat(element.quantity))
                    if (this.MinValueStocks > element[this.Coin]) this.MinValueStocks = element[this.Coin]
                }


                resolve(true)
            });
        },
    }
}
</script>

<style>
</style>