<template>
   <span class="input-group-append">
        <b-dropdown  :text="this.$store.state.portfolio.DataBase.settings.usdCoin ? '$' : 'R$'" variant="cyan " >
            <b-dropdown-item href="#" @click="change(false)">R$ (BRL)</b-dropdown-item>
            <b-dropdown-item href="#" @click="change(true)">$ (USD)</b-dropdown-item>
        </b-dropdown>
        <!-- <a href="#" class="btn btn-gray-dark pt-2">R$</a> -->
    </span>

   
</template>

<script>
import { mapActions } from 'vuex'
import firebase from "firebase/app"
export default {
    mounted(){
        //SE O USUARIO NÃO FOR NIVEL 2 OU MAIOR ELE NAO PODE TROCAR A MOEDA DO SISTEMA
        if(this.PremiumType < 2 ) this.$store.state.portfolio.DataBase.settings.usdCoin = false
    },
    computed:{
        PremiumType(){
            return this.$store.state.portfolio.DataBase.settings.premium.type
        },
    },
    methods:{
        ...mapActions('global', ['MsgNotPermission']),
        change(value){
            if(this.PremiumType < 2 )  {
                this.MsgNotPermission('Essa funcionalidade só está disponível para usuários do nível <b>profissional</b><br>Clique aqui e <a href="pricing"> <b> Seja um Premium </b></a> profissional')
                return
            }
            this.$store.state.portfolio.DataBase.settings.usdCoin = value
            this.Save()
        },
        Save(){
            firebase.firestore().collection('account')
                .doc(this.$store.state.user.CurrentUser.uid)
                .set(this.$store.state.portfolio.DataBase)
            .catch((error) => {
                let params = new URLSearchParams()
                params.append('email', this.$store.state.user.CurrentUser.email )
                params.append('event',  JSON.stringify(error) )
                this.$api.post("/Errorlog/Set", params)
                console.log(error);
            })
        },
    }
}
</script>

<style>

</style>
