<template>
  <div class="container">
    <div v-if="DataBase != null">
      <br />
      <div class="row">
        <div class="col-12">
          <div v-show="calculating" class="card">
            <div class="m-4 text-center">
              <h4>Aguarde!!!</h4>
              <h6>
                Fique tranquilo, pois estamos realizando todos os cálculos
                necessários! 😎
              </h6>
              <div class="progress progress-lg">
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated bg-cyan"
                  :style="'width: ' + ProgressBarValue + '%'"
                ></div>
              </div>
              <div class="text-center">{{ ProgressBarValue }}%</div>
            </div>
          </div>

          <div v-show="!calculating" class="row">
            <div
              v-if="
                DataBase.portfolio.class.filter(
                  (item) => item.objectivePercentage > 0
                ).length > 1
              "
              class="col-lg-12"
              v-show="ShowCardSettings"
            >
              <div class="card">
                <div class="card-header">
                  <h3
                    class="card-title"
                    @click="ShowSettings = !ShowSettings"
                    style="cursor: pointer"
                  >
                    Configurações de rebalanceamento
                  </h3>
                  <div class="card-options">
                    <a
                      href="#"
                      class="card-options-collapse"
                      data-toggle="card-collapse"
                      @click="ShowSettings = !ShowSettings"
                    >
                      <font-awesome-icon
                        class="text-black"
                        :icon="'chevron-' + (ShowSettings ? 'up' : 'down')"
                      />
                    </a>
                    <a
                      href="#"
                      class="card-options-remove"
                      data-toggle="card-remove"
                      @click="ShowCardSettings = false"
                    >
                      <font-awesome-icon class="text-black" icon="times" />
                    </a>
                  </div>
                </div>
                <div class="m-4" v-show="ShowSettings">
                  <SelectGroupPills :DataBase="DataBase" />
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="card">
                <div class="mt-4 mr-4 ml-4">
                  <CalculateButton :DataBase="DataBase" :radioCoin="Coin" />
                </div>

                <!-- Layout para desktop -->
                <div v-if="!$isMobile()" class="col-lg-12">
                  <div class="card">
                    <div class="col-12 table-responsive">
                      <table
                        class="table card-table table-vcenter text-nowrap table-hover"
                      >
                        <TableHead
                          :portfolio="PortfolioCalc"
                          :DataBase="DataBase"
                          :CallOrderByIni="
                            this.$store.state.portfolio.CallOrderByIni
                          "
                        />
                        <TableBody
                          :Modal="Modal"
                          :PortfolioCalc="PortfolioCalc"
                          :DataBase="DataBase"
                        />
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Layout para mobile -->
            <div v-if="$isMobile()" class="col-lg-12">
              <MobileCards
                :Modal="Modal"
                :PortfolioCalc="PortfolioCalc"
                :CallOrderByIni="this.$store.state.portfolio.CallOrderByIni"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="edit"
      :title="
        (RF
          ? 'Atualizar: '
          : this.$store.state.global.buy
          ? 'Comprar: '
          : 'Vender: ') + codeEdit
      "
      @ok="SaveEdit"
      @cancel="ClearFields"
      @close="ClearFields"
      @hide="ClearFields"
      :ok-title="
        RF ? 'Atualizar' : this.$store.state.global.buy ? 'Comprar' : 'Vender'
      "
      :ok-variant="
        RF ? 'cyan' : this.$store.state.global.buy ? 'success' : 'danger'
      "
      cancel-title="Cancelar"
      cancel-variant="gray"
    >
      <BuyOrSell v-if="!RF" />
      <!-- <div class="form-group">
                <label class="form-label">Data</label>
                <input type="date" class="form-control" name="example-text-input" v-model="dateEdit" >
            </div> -->
      <div class="form-group">
        <label class="form-label">Quantidade</label>
        <input
          type="number"
          ref="quantity"
          class="form-control"
          name="example-text-input"
          v-on:keyup.enter="keyupEnter(RF, 0)"
          v-model="quantityEdit"
        />
      </div>
      <div v-if="RF" class="form-group">
        <label class="form-label">Preço</label>
        <div class="input-group">
          <CoinControll />
          <input
            type="number"
            ref="price"
            class="form-control"
            name="example-text-input"
            v-on:keyup.enter="keyupEnter(RF, 1)"
            v-model="priceEdit"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import firebase from "firebase/app";
import TableHead from "@/components/rebalancing/TableHead";
import TableBody from "@/components/rebalancing/TableBody";
import MobileCards from "@/components/rebalancing/MobileCards";
import SelectGroupPills from "@/components/rebalancing/SelectGroupPills";
import CalculateButton from "@/components/rebalancing/CalculateButton";
import BuyOrSell from "@/components/widgets/BuyOrSell";
import CoinControll from "@/components/widgets/CoinControll";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TableHead,
    SelectGroupPills,
    CalculateButton,
    TableBody,
    BuyOrSell,
    CoinControll,
    MobileCards,
  },
  data() {
    return {
      ShowSettings: true,
      ShowCardSettings: true,
      Coin: "priceBRL",
      codeEdit: "",
      quantityEdit: 0.0,
      priceEdit: 0.0,
      RF: false,
      Dolar: 0,
    };
  },
  computed: {
    ...mapGetters("portfolio", ["GetDecimalPlaces"]),
    DataBase() {
      if (this.$store.state.portfolio.DataBase == null) return null;
      return this.$store.state.portfolio.DataBase;
    },
    PortfolioCalc() {
      return this.$store.state.portfolio.PortfolioCalc;
    },
    calculating() {
      return this.$store.state.portfolio.calculating;
    },
    ProgressBarValue() {
      return this.$store.state.portfolio.progress;
    },
    PremiumType() {
      return this.$store.state.portfolio.DataBase.settings.premium.type;
    },
  },
  mounted() {
    if (this.$store.state.portfolio.DataBase != null) {
      this.$store.state.portfolio.DataBase.portfolio.class.map((element) => {
        this.$store.state.portfolio.PortfolioCalc = [];
        element.isBuy = element.objectivePercentage > 0;
      });
    }

    firebase.analytics().logEvent("View page Rebalancing");
    this.$store.state.portfolio.DataBase.newPortfolio = false;

    this.$newApi.get("dolar").then(async (response) => {
      this.Dolar = response.data;
    });
  },
  methods: {
    ...mapActions("global", ["MsgNotPermission"]),
    keyupEnter(HasRF, button) {
      if (HasRF && button == 0) {
        this.$refs.price.focus();
        this.$refs.price.select();
      } else {
        this.SaveEdit();
        this.$bvModal.hide("edit");
      }
    },
    Modal(code) {
      this.codeEdit = code;
      this.$store.state.global.buy = true;
      this.RF =
        this.DataBase.portfolio.stocks.filter((item) => item.code == code)[0]
          .class == "rf"
          ? true
          : false;

      if (this.RF) {
        let StockEdit = this.DataBase.portfolio.stocks.filter(
          (item) => item.code == this.codeEdit
        )[0];
        this.quantityEdit = StockEdit.quantity;
        this.priceEdit = parseFloat(
          this.$store.state.portfolio.DataBase.settings.usdCoin
            ? StockEdit.priceUSD
            : StockEdit.priceBRL
        ).toFixed(2);
      }

      this.$bvModal.show("edit");

      setTimeout(() => {
        this.$refs.quantity.focus();
        this.$refs.quantity.select();
      }, 100);
    },
    SaveEdit() {
      if (
        this.PremiumType == 0 &&
        this.$store.state.portfolio.DataBase.portfolio.stocks.length > 15
      ) {
        this.MsgNotPermission(
          'Você atingiu o limite de 15 de ativos cadastrados, para realizar esse lançamento é necessário remover alguns ativos de sua carteira ou <a href="pricing"> <b> Seja Premium </b> </a>.'
        );
        return;
      }

      if (typeof parseFloat(this.quantityEdit) != "number") {
        this.quantityEdit = 0;
        this.$swal(
          "Quantidade inválida!",
          "Por favor, informe um número válido.",
          "error"
        );
        return;
      }
      if (
        typeof parseFloat(this.quantityEdit) == "number" &&
        isNaN(parseFloat(this.quantityEdit))
      ) {
        this.quantityEdit = 0;
        this.$swal(
          "Quantidade inválida!",
          "Por favor, informe um número válido.",
          "error"
        );
        return;
      }

      let StockEdit = this.DataBase.portfolio.stocks.filter(
        (item) => item.code == this.codeEdit
      )[0];
      const DecimalPlaces = this.GetDecimalPlaces(StockEdit.class);

      if (
        parseFloat(StockEdit.quantity) < parseFloat(this.quantityEdit) &&
        !this.$store.state.global.buy
      ) {
        this.$swal(
          "Quantidade insuficientes para essa operação",
          "Você está tentando lançar uma venda maior que a quantidade que tem em carteira.",
          "error"
        );
        return;
      }

      if (this.$store.state.global.buy)
        StockEdit.quantity = parseFloat(
          parseFloat(StockEdit.quantity) + parseFloat(this.quantityEdit)
        ).toFixed(DecimalPlaces);
      else
        StockEdit.quantity = parseFloat(
          parseFloat(StockEdit.quantity) - parseFloat(this.quantityEdit)
        ).toFixed(DecimalPlaces);

      if (this.RF) {
        StockEdit.priceBRL = parseFloat(
          this.$store.state.portfolio.DataBase.settings.usdCoin
            ? this.priceEdit * this.Dolar
            : this.priceEdit
        ).toFixed(2);
        StockEdit.priceUSD = parseFloat(
          this.$store.state.portfolio.DataBase.settings.usdCoin
            ? this.priceEdit
            : this.priceEdit / this.Dolar
        ).toFixed(2);
        StockEdit.quantity = parseFloat(this.quantityEdit).toFixed(
          DecimalPlaces
        );
      }

      this.ToastSuccess(
        "success",
        "Operação " + (this.RF ? "atualizada" : "realizada") + " sucesso"
      );
      this.Save();
    },
    ClearFields() {
      this.quantityEdit = 0.0;
      this.priceEdit = 0.0;
    },
    Save() {
      firebase
        .firestore()
        .collection("account")
        .doc(this.$store.state.user.CurrentUser.uid)
        .set(this.DataBase)
        .catch((error) => {
          let params = new URLSearchParams();
          params.append("email", this.$store.state.user.CurrentUser.email);
          params.append("event", JSON.stringify(error));
          this.$api.post("/Errorlog/Set", params);
          console.log(error);
        });
    },
    ToastSuccess(icon, title) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: icon,
        title: title,
      });
    },
  },
};
</script>

<style>
.table th,
.table td {
  padding: 2px;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
  font-size: smaller;
}
.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: #b0faf1;
}
.price {
  color: darkslateblue;
}
</style>
