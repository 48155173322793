<template>
    <div class="form-group">
        <label class="form-label text-red">Somente as categorias selecionadas entrarão no cálculo de rebalanceamento</label>
        <div class="selectgroup selectgroup-pills">
            <label class="selectgroup-item">
                <input type="checkbox" name="checkbox" class="selectgroup-input" @click="chance(null)" v-model="all">
                <span class="selectgroup-button">
                    <font-awesome-icon icon="exchange-alt" />
                    Todos
                </span>
            </label>
            <label v-for="item in DataBase.portfolio.class" :key="item.code" v-show="item.objectivePercentage > 0" class="selectgroup-item">
                <input type="checkbox" name="checkbox" class="selectgroup-input" @click="chance(item.code)"  v-model="item.isBuy"  >
                <span class="selectgroup-button">
                    <font-awesome-icon :icon="item.code == 'criptomoedas' ? ['fab' , $options.filters.ICON(item.code)] : $options.filters.ICON(item.code) " />
                    {{item.code | NAME}}
                </span>
            </label>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: {
        DataBase: { type: Object, required: true },
    },
    data() {
		return {
			all: true,
		}
    },
    computed:{
        PremiumType(){
            return this.$store.state.portfolio.DataBase.settings.premium.type
        },
    },
    watch:{
        all(){
            this.$store.state.portfolio.DataBase.portfolio.class.forEach(element => {
                element.isBuy = this.all
            });
        }
    },
    methods:{
        ...mapActions('global', ['MsgNotPermission']),
        chance(code){
            // console.log(this.$store.state.portfolio.DataBase.portfolio.class);
            let ClassStock = this.$store.state.portfolio.DataBase.portfolio.class.filter(item => item.code == code)[0]
            if(this.PremiumType < 2 ){
                this.MsgNotPermission('Essa funcionalidade só está disponível para usuários do nível <b>profissional</b><br>Clique aqui e <a href="pricing"> <b> Seja um Premium </b></a> profissional')
                setTimeout(() => {
                    ClassStock.isBuy = true
                }, 100);
                return
            }
        }
    }
}
</script>

<style>

</style>