<template>
    <div class="text-center mb-4">
        <div class="row">
            <span class="input-group-append col-6">
                <button type="button" :class="'btn btn' + (buy ? '' : 'outline') + '-success btn-block'" @click="chance()"  >
                    (<font-awesome-icon icon="plus"/>)
                    Comprar
                </button>
            </span>
            <span class="input-group-append  col-6">
                <button type="button" :class="'btn btn' + (!buy ? '' : 'outline') + '-danger btn-block'" @click="chance()">
                    (<font-awesome-icon icon="minus"/>)
                    Vender
                </button>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    computed:{
        buy:{
            get: function() {  return this.$store.state.global.buy },
            set: function(newValue) {this.$store.state.global.buy = newValue} 
        },
    },
    methods:{
        chance(){
            this.buy = !this.buy
        }
    }
}
</script>

<style>

</style>