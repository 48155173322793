<template>
    <thead>
        <tr>
            <th class="">#</th>
            <th v-for="(head, index)  in header" :key="index" class="text-dark text-center" style="cursor: pointer;" v-b-tooltip.hover.right :title="head.tooltip" @click="OrderBy(head.code)"> 
                <b>{{head.title}}</b>&nbsp;
                <font-awesome-icon class="text-teal" :icon="head.icon"/>
            </th>
            <th class="text-dark text-center"><b>Ações</b></th>
        </tr>
    </thead>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            header:[ 
                { code:'code', icon:'arrows-alt-v', title:'Código', tooltip:"Codigo de negociação do etivo." },
                { code:'note', icon:'arrows-alt-v', title:'Nota', tooltip:"Informe sua nota para esse ativo." },
                { code:'topPriceBRL', icon:'arrows-alt-v', title:'Preço Teto', tooltip:"Preço máximo que deseja pagar de um ativo, caso o valor esteja zerado esse ativo vai entrar no rebalanceamento."},
                { code:'quantity', icon:'arrows-alt-v', title:'Custódia', tooltip:"Quantidade que você tem em custódia."},
                { code:'class', icon:'arrows-alt-v', title:'Classe' , tooltip:"Classe / Categoria do Ativo."},
                { code:'percentageInClass', icon:'arrows-alt-v', title:'% Classe', tooltip:"Porcentagem que esse ativo tem dentro desta categoria."},
                { code:'percentageObjective', icon:'arrows-alt-v', title:'% Carteira', tooltip:"Porcentagem que esse ativo tem dentro da sua carteira."},
            ],
        }
    },
    computed:{
        ...mapGetters('portfolio', ['Percentage','PercentageByClass']),
        DataBase(){
            return this.$store.state.portfolio.DataBase
        },
    },
    methods:{
        OrderBy(Col){
            let OrderDesc = ( this.header.filter(element =>  element.code == Col)[0].icon == 'long-arrow-alt-up' ) ? false : true;

            for (let index = 0; index < this.header.length; index++) {
                this.header[index].icon = 'arrows-alt-v'
            }
            
            this.header.filter(element =>  element.code == Col)[0].icon = OrderDesc ? 'long-arrow-alt-up'  : 'long-arrow-alt-down'
            this.DataBase.portfolio.stocks.sort((a,b) => {

                if(OrderDesc){
                    let NewA = b;
                    let NewB = a;
                    b = NewB;
                    a = NewA;
                }

                if(Col == 'percentageInClass') 
                    return this.Percentage(a.class, this.PercentageByClass(a.code)) - this.Percentage(b.class, this.PercentageByClass(b.code))

                
                if(typeof a[Col] === "number" || Math.floor(a[Col]) === a[Col] || isFinite(a[Col]))
                    return a[Col] - b[Col];
                else
                    return a[Col].localeCompare(b[Col]);
            })
        }
    }
}
</script>

<style>

</style>