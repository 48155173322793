<template>
    <tbody v-if="DataBase != null">
        <tr v-for="(itens, index) in DataBase" :key="index">
            <td>{{index + 1}}</td>
            <td class="text-center ">{{itens.code}}</td>
            <td class="text-center ">{{ parseFloat(itens.note).toFixed(2)}}</td>
            <td class="text-center ">
                <div v-if="itens.class != 'rf' && PremiumType > 1">
                    <div> 
                        {{itens.topPriceBRL  | BRL}}
                    </div>
                    <div>
                        {{itens.topPriceUSD  | USD}}
                    </div>
                </div>
                <div v-else> - </div>
                <div v-if="PremiumType < 2">
                    <span v-b-tooltip.hover title="A funcionalidade preço teto só está disponível para usuários do nível profissional">
                        <font-awesome-icon icon="exclamation-circle" class="text-red"/>
                    </span>
                </div>
            </td>
            <td class="text-center ">{{itens.quantity  | QTD}}</td>
            <td class="text-center"  v-html="$options.filters.TAG(itens.class)"></td>
            <td class="text-center">{{ PercentageByClass(itens.code) | % }}</td>
            <td class="text-center">{{ Percentage(itens.class, PercentageByClass(itens.code)) | % }}</td>
            <td class="text-center">
                <span v-b-tooltip.hover.bottom title="Editar"  @click="Edit(itens.code)">
                   <font-awesome-icon style="cursor: pointer;" icon="edit" class="text-blue"/>
                </span> 
                |
                <span v-b-tooltip.hover.bottom title="Excluir" @click="Delete(itens.code)">
                   <font-awesome-icon style="cursor: pointer;" icon="trash-alt" class="text-red"/>
                </span> 
               <!-- |
               <span v-b-tooltip.hover title="Lançar suas operações"><font-awesome-icon style="cursor: pointer;" icon="plus" class="text-green"/></span> -->
            </td>
        </tr>
    </tbody>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        ClassStock: { type: String, required: true },
        Delete: { type: Function, required: true },
        Edit: { type: Function, required: true },
    },
    computed:{
        ...mapGetters('portfolio', ['Percentage','PercentageByClass']),
        DataBase(){
            let NewDataBase = null
            if(this.$store.state.portfolio.DataBase != null){
                NewDataBase = this.$store.state.portfolio.DataBase.portfolio.stocks.filter(item => item.class == this.ClassStock)
            }
            return NewDataBase
        },
        PremiumType(){
            return this.$store.state.portfolio.DataBase.settings.premium.type
        },
    },

}
</script>

<style>
    .custom-switch {
        padding-left: 0px;
    }
</style>