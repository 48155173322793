<template>
  <b-modal 
            id="BuyInfo" 
            title="Informações do aporte" 
            size="lg"
            hide-footer
        >
            <b-table hover :items="items" :fields="fields" >
                <template #cell(class)="data">
                    <div class="text-center">
                        <span v-if="data.item.class == 'Total'">{{data.item.class}}</span>
                        <span v-else  v-html="$options.filters.TAG(data.item.class)" ></span>
                    </div>
                </template>

            </b-table>

            <b-button class="mt-2" variant="outline-info" block @click="$bvModal.hide('BuyInfo')">Fechar</b-button>
        </b-modal>
</template>

<script>
export default {
    data() {
        return {
            fields: [
                { key: 'class', label: 'Classe', sortable: true, sortDirection: 'desc',class: 'text-center' },
                { key: 'value', label: 'Valor em Reais', sortable: true, class: 'text-center' },
                { key: 'valueUSD', label: 'Valor em Dólar', sortable: true, class: 'text-center' },
                { key: 'percent', label: 'Porcentagem', sortable: true, class: 'text-center' },
            ],
        }
    },  
    computed:{
        items(){
            if(this.$store.state.portfolio.DataBase == null) return
            let portfolio = this.$store.state.portfolio.DataBase.portfolio
            let items = []
            let ValueBuy = 0
            let SumValue = 0
            let SumValueUSD = 0
            let SumPercent = 0

            for (let index = 0; index < portfolio.stocks.length; index++) {
                const element = portfolio.stocks[index];
                ValueBuy += element.quantityBuy * element.priceBRL
            }

            for (let index = 0; index < portfolio.class.length; index++) {
                let priceBRL = 0
                let priceUSD = 0
                const element = portfolio.class[index];
                const StockByClass = portfolio.stocks.filter(stock =>( stock.class == element.code))

                for (let indexStocks = 0; indexStocks < StockByClass.length; indexStocks++) {
                    const elementStock = StockByClass[indexStocks]
                    priceBRL += elementStock.quantityBuy * elementStock.priceBRL
                    priceUSD += elementStock.quantityBuy * elementStock.priceUSD
                }
                
                if(priceBRL <= 0) continue

                items.push({
                    class: element.code,
                    value: this.$options.filters.BRL(priceBRL),
                    valueUSD: this.$options.filters.BRL(priceUSD),
                    percent: this.$options.filters.PERCENT(priceBRL * 100 / ValueBuy) 
                })

                SumValue += priceBRL
                SumValueUSD += priceUSD
                SumPercent += (priceBRL * 100 / ValueBuy)
            }

            items.push({
                class: "Total",
                value: this.$options.filters.BRL(SumValue),
                valueUSD: this.$options.filters.USD(SumValueUSD),
                percent: this.$options.filters.PERCENT(SumPercent) 
            })



            return items
        },
    }

}
</script>

<style>

</style>