<template>
    <div v-if="DataBase != null" >
        <b-tabs  content-class="mt-3">
            <div v-for="item in DataBase.portfolio.class" :key="item.code">
                <b-tab v-if="(
                            item.objectivePercentage > 0 || 
                            (DataBase.portfolio.stocks.filter(stock => stock.class == item.code).length > 0)
                        )" 
                        :title="$options.filters.NAME(item.code)" 
                        @click="loading()"
                    >
                    <div v-if="!Loading">
                        <div class="row"> 
                            <Form :keyupEnterDad="keyupEnterDad" :modal="false" :StockClass="item.code"/>
                            <div class="col-12 text-right mb-2">
                                <button  :ref="'add-' + item.code" :class="'btn btn-cyan' + ( $isMobile() ? ' btn-block': '')" :disabled="awaitInsert"  @click="insert(item.code)">
                                    <span>
                                        Adicionar 
                                        <font-awesome-icon  icon="spinner" v-show="awaitInsert" class="fa-spin header-loading" size="sm" />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div v-if="!$isMobile()" class="card">
                            <div class="table-responsive">
                                <table class="table card-table table-vcenter text-nowrap table-hover">
                                    <TableHead />
                                    <TableBody :ClassStock="item.code" :Delete="Delete" :Edit="Edit" />
                                </table>
                            </div>
                        </div>
                        <div v-if="$isMobile()" >
                            <MobileCards :ClassStock="item.code" :Delete="Delete" :Edit="Edit" />
                        </div>
                    </div>
                </b-tab>
            </div>
        </b-tabs>
        <div v-if="Loading" class="text-center m-6">
            <font-awesome-icon  icon="spinner" class="fa-spin header-loading text-primary" size="8x" />
        </div>

        <b-modal 
            id="edit" 
            :title="'Editar ativo: ' + codeEdit" 
            @ok="SaveEdit" @cancel="ClearFields" @close="ClearFields" @hide="ClearFields" 
            ok-title="Atualizar" ok-variant="cyan" 
            cancel-title="Cancelar" cancel-variant="red"
        >
            <Form v-if="classEdit != ''" :modal="true" :StockClass="classEdit" :keyupEnterDad="keyupEnterModal" />
        </b-modal>
    </div>

    
</template>

<script>
import Swal from 'sweetalert2'
import firebase from "firebase/app"
import TableHead from "@/components/goal/TableHead"
import TableBody from "@/components/goal/TableBody"
import MobileCards from "@/components/goal/MobileCards"
import Form from "@/components/goal/Form"
import { mapGetters,mapActions } from 'vuex'
export default {
    components: { TableHead, TableBody, Form, MobileCards },
    computed:{
        ...mapGetters('portfolio', ['Percentage','PercentageByClass','GetDecimalPlaces']),
        DataBase(){
            return this.$store.state.portfolio.DataBase
        },
        code:{
            get: function() {  return this.$store.state.global.code },
            set: function(newValue) {this.$store.state.global.code = newValue} 
        },
        note:{
            get: function() {return this.$store.state.global.note },
            set: function(newValue) {this.$store.state.global.note = newValue} 
        },
        topPrice:{
            get: function() {return this.$store.state.global.topPrice},
            set: function(newValue) {this.$store.state.global.topPrice = newValue} 
        },
        quantity:{
            get: function() {return this.$store.state.global.quantity},
            set: function(newValue) {this.$store.state.global.quantity = newValue} 
        },
        PremiumType(){
            return this.$store.state.portfolio.DataBase.settings.premium.type
        },
    },
    data() {
        return {
            awaitInsert: false,
            codeEdit: 'code',
            classEdit: "",
            DelaySave: 0,
            DolarValue:1,
            Loading:false
        }
    },
    mounted(){
        this.$newApi.get('dolar')
            .then(async response => {
                this.DolarValue = response.data 
            })
    },
    methods:{
        ...mapActions('global', ['MsgNotPermission']),
        loading(){
            this.Loading = true
            setTimeout(() => {
                this.Loading = false 
            }, 100);
        },
        async insert(className){
            if(this.PremiumType == 0 && this.$store.state.portfolio.DataBase.portfolio.stocks.length >= 15){
                this.MsgNotPermission("Você atingiu o limite de cadastro de ativos, para adicionar mais ativos <a href='pricing'> <b> Seja Premium </b> </a> de nossa plataforma")
                this.awaitInsert = false
                return
            }
            
            await this.AdjustFields() //Deixo todos os campos validos para o insert
            let code = ""
            if(className == 'criptomoedas') code = this.code + "-USD"
            else code = this.code
            if(!await this.ValidationInsert(code)) return // Valido se posso inserir esse ativo

            this.awaitInsert = true

            if(className == 'rf'){
                await this.PushRF() //Coloco esse ativo no Json
                
                await this.udpatePercentageObjective()//Atualizo a % objetiva de todos os ativos
                this.ClearFields() //Limpo os campos
                this.Save()
                this.ToastSuccess('success', 'Ativo adicionado com sucesso!')
                this.awaitInsert = false
                return
            }
            

            // let params = new URLSearchParams()
            // params.append('code', code)
            // params.append('currency', "BRL")

            this.$newApi.get(`price/${code}`)
                .then(async response => {
                    if(!response.data.Error){
                        await this.PushSotcks(response, className) //Coloco esse ativo no Json

                        await this.udpatePercentageObjective()//Atualizo a % objetiva de todos os ativos
                        this.ClearFields() //Limpo os campos
                        this.Save()
                        this.ToastSuccess('success', 'Ativo adicionado com sucesso!')
                        this.awaitInsert = false
                    } else {
                        this.$swal("Atenção", "O ativo '" + this.code + "' não foi reconhecido dentro da classe de " + this.$options.filters.NAME(className), "warning")
                        this.ClearFields() //Limpo os campos
                        this.awaitInsert = false
                    }
                })
        },
        async udpatePercentageObjective(){
            return new Promise(resolve => {
                for (let index = 0; index < this.$store.state.portfolio.DataBase.portfolio.stocks.length; index++) {
                    const element = this.$store.state.portfolio.DataBase.portfolio.stocks[index];
                    element.percentageObjective = this.Percentage(element.class, this.PercentageByClass(element.code)) 
                }
                resolve(true)
            });
        },
        async PushRF(){
            return new Promise(resolve => {
                //topPrice na RENDA FIXA eu faço virar o valor do ativo
                this.$store.state.portfolio.DataBase.portfolio.stocks.push({
                    "class":'rf',
                    "code": this.code,
                    "isBuy": true,
                    "note": parseFloat(this.note),
                    "topPriceBRL":0,
                    "topPriceUSD":0,
                    "quantity":parseFloat(this.quantity),
                    "priceBRL":parseFloat(this.$store.state.portfolio.DataBase.settings.usdCoin ? this.topPrice * this.DolarValue : this.topPrice).toFixed(2),
                    "priceUSD":parseFloat(this.$store.state.portfolio.DataBase.settings.usdCoin ? this.topPrice : this.topPrice / this.DolarValue).toFixed(2),
                    "currency":(this.$store.state.portfolio.DataBase.settings.usdCoin ? 'BRL' : 'UDS'),
                })

                resolve(true)
            });
        },
        async PushSotcks(response, className) {
            return new Promise(resolve => {
                this.$store.state.portfolio.DataBase.portfolio.stocks.push({
                    "class":className,
                    "code": this.code,
                    "isBuy": true,
                    "note": parseFloat(this.note),
                    "topPriceBRL":parseFloat(this.$store.state.portfolio.DataBase.settings.usdCoin ? this.topPrice * this.DolarValue : this.topPrice).toFixed(2),
                    "topPriceUSD":parseFloat(this.$store.state.portfolio.DataBase.settings.usdCoin ? this.topPrice : this.topPrice / this.DolarValue).toFixed(2),
                    "quantity":parseFloat(this.quantity),
                    "priceBRL":parseFloat(response.data.PriceBrl),
                    "priceUSD":parseFloat(response.data.PriceUsd),
                    "currency":response.data.Currency,
                })

                resolve(true)
            });
        },
        async ValidationInsert(code){
            return new Promise(resolve => {
                if(this.code == "") resolve(false)

                for (let index = 0; index < this.DataBase.portfolio.stocks.length; index++) {
                    const element = this.DataBase.portfolio.stocks[index];

                    let ValidationCode = element.class == 'criptomoedas' ? element.code + "-USD" : element.code

                    // console.log(className, element.code, code, code == ValidationCode);
                    if ( code == ValidationCode) {
                        this.$swal("Atenção", "Esse ativo já foi inserido", "warning")
                        resolve(false)
                    }
                    // if(className  != 'criptomoedas' && code == element.code){
                    //     this.$swal("Atenção", "Esse ativo já foi inserido", "warning")
                    //     resolve(false)
                    // }
                    
                }

                resolve(true)
            });
        },
        async AdjustFields(){
            return new Promise(resolve => {
                this.code = this.code.toUpperCase().trim()
                if(isNaN(parseFloat(this.note)) || !isFinite(parseFloat(this.note)) || parseFloat(this.note) < 0) this.note = 0
                if(isNaN(parseFloat(this.topPrice)) || !isFinite(parseFloat(this.topPrice)) || parseFloat(this.topPrice) < 0 ) this.topPrice = 0
                if(isNaN(parseFloat(this.quantity)) || !isFinite(parseFloat(this.quantity)) || parseFloat(this.quantity) < 0 ) this.quantity = 0

                this.note = parseFloat(this.note).toFixed(2)
                this.topPrice = parseFloat(this.topPrice).toFixed(2)

                resolve(true)
            });
        },
        ClearFields(){
            setTimeout(() => {
                this.code = ""
                this.note = 0.00
                this.topPrice = 0.00
                this.quantity = 0
            }, this.DelaySave);
        },
        async SaveEdit(){
            if(this.PremiumType == 0 && this.$store.state.portfolio.DataBase.portfolio.stocks.length > 15){
                this.MsgNotPermission('Você atingiu o limite de 15 de ativos cadastrados, para editar é necessário remover alguns ativos de sua carteira ou  <a href="pricing"> <b> Seja Premium </b> </a>.')
                this.awaitInsert = false
                return
            }

            this.DelaySave = 100
            let stock = this.$store.state.portfolio.DataBase.portfolio.stocks.filter(item => item.code == this.codeEdit)[0]
            await this.AdjustFields() //Deixo todos os campos validos para o insert

            stock.note = this.note
            if(stock.class == 'rf'){
                stock.priceBRL = parseFloat(this.$store.state.portfolio.DataBase.settings.usdCoin ? this.topPrice * this.DolarValue : this.topPrice).toFixed(2)
                stock.priceUSD = parseFloat(this.$store.state.portfolio.DataBase.settings.usdCoin ? this.topPrice : this.topPrice / this.DolarValue).toFixed(2)
            }else{
                stock.topPriceBRL = parseFloat(this.$store.state.portfolio.DataBase.settings.usdCoin ? this.topPrice * this.DolarValue : this.topPrice).toFixed(2)
                stock.topPriceUSD = parseFloat(this.$store.state.portfolio.DataBase.settings.usdCoin ? this.topPrice : this.topPrice / this.DolarValue).toFixed(2)
            }
            stock.quantity = this.quantity

            // this.ClearFields() //Limpo os campos
            await this.udpatePercentageObjective() //Atualizo a % objetiva de todos os ativos

            this.Save()
            this.DelaySave = 0
        },
        async Edit(code){
            let stock = this.$store.state.portfolio.DataBase.portfolio.stocks.filter(item => item.code == code)[0]
            this.note = stock.note
            if(stock.class == 'rf')//Renda Fixa Não tem topPrice e no lugar tem o valor do ativo
                this.topPrice = (this.$store.state.portfolio.DataBase.settings.usdCoin ? stock.priceUSD : stock.priceBRL)
            else
                this.topPrice = (this.$store.state.portfolio.DataBase.settings.usdCoin ? stock.topPriceUSD : stock.topPriceBRL)
            this.quantity = stock.quantity
            
            this.$bvModal.show('edit')
            this.codeEdit = code
            this.classEdit = stock.class
        },
        Delete(code){
            //Defino Class do Swal
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success mr-2',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Tem certeza?',
                html: "Você está prestes a excluir o ativo: <b>" + code + "</b>. Não será possível reverter isso!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Cancelar',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    //Acho o index do ativo que vou remover
                    for (let index = 0; index < this.$store.state.portfolio.DataBase.portfolio.stocks.length; index++) {
                        const element = this.$store.state.portfolio.DataBase.portfolio.stocks[index];
                        if(element.code == code){
                            this.$store.state.portfolio.DataBase.portfolio.stocks.splice(index, 1);
                            break
                        } 
                    }

                    this.Save()
                    this.ToastSuccess('success', 'Seu ativo: ' + code + ' foi excluído.')
                }
            })
        },
        ToastSuccess(icon, title){
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: icon,
                title: title
            })
        },
        keyupEnterDad(where){
            this.$refs[where][0].click()
            // this.insert(code)
        },
        keyupEnterModal(){
            this.SaveEdit()
            this.$bvModal.hide('edit')
        },
        Save(){
            firebase.firestore().collection('account')
                .doc(this.$store.state.user.CurrentUser.uid)
                .set(this.DataBase)
            .catch((error) => {
                let params = new URLSearchParams()
                params.append('email', this.$store.state.user.CurrentUser.email )
                params.append('event',  JSON.stringify(error) )
                this.$api.post("/Errorlog/Set", params)
                console.log(error);
            })
            // this.ToastSuccess("success", "Salvo com sucesso!")
        },
    }
}
</script>

<style scoped>
    .header-loading {
        margin-top: 0px;
    }
</style>