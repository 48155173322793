<template>
    <div v-if="DataBase != null" class="card-slider">
        <div :class="'pt-2 pr-4 pl-4 text-' + $options.filters.COLOR(className)">
            <div class="row align-items-center">
                <label class="col-lg-8 col-6 mt-2">
                    <div :class="'card-value stamp  br-100 bg-' + $options.filters.COLOR(className)">
                        <font-awesome-icon :icon="className == 'criptomoedas' ? ['fab' , $options.filters.ICON(className)] : $options.filters.ICON(className) " />
                    </div>
                    <span class="pl-2">
                        {{className | NAME}}
                    </span>
                </label>
                <div class="col-lg-4 col-6">
                    <input step="1" type="number"   class="form-control" v-model="ClassPorcent" />
                </div>
            </div>
        </div>
        <div class="card-body p-2">
            <div class="row align-items-center">
                <div class="col-12 pl-4 pr-4">
                    <vue-slider ref="slider" v-model="ClassPorcent" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import firebase from "firebase/app"
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { mapGetters } from 'vuex'
export default {
    components: { VueSlider  },
    props: {
        className: { type: String, required: true },
        SumPercent: { type: Number, required: true },
        SumTabIndex: { type: Function, required: true }, 
    },
    data() {
        return {
            Timer:null
        }
    },
    computed:{
        ...mapGetters('portfolio', ['Percentage','PercentageByClass']),
        DataBase(){
            return this.$store.state.portfolio.DataBase
        },
        ClassPorcent: { 
            get: function() {
                return parseFloat(this.$store.state.portfolio.DataBase.portfolio.class.filter(item => item.code == this.className)[0].objectivePercentage)
            },
            set: function(newValue) {
                this.$store.state.portfolio.DataBase.portfolio.class.filter(item => item.code == this.className)[0].objectivePercentage = newValue
            } 
        }
    },
    watch:{
        ClassPorcent(newVal, oldVal){
            if(isNaN(newVal) || !isFinite(newVal)) this.ClassPorcent = parseFloat(0)
            if(newVal > 100){
                this.ClassPorcent = 100
                return
            } 
            if(newVal < 0){
                this.ClassPorcent = 0
                return
            } 

            if(this.SumPercent > 100) this.ClassPorcent = 100 - (this.SumPercent - this.ClassPorcent)

            this.$refs.slider.setValue(this.ClassPorcent)

            //COLOCO UM TIMEROUT PARA SÓ SALVAR DEPOIS DE UM SEGUNDO PARA NÂO FICAR GRAVANDO QUE NEM UM LOUCO!
            if(this.Timer != null){
                clearTimeout(this.Timer);
                this.Timer = null;        
            }
            this.Timer = setTimeout(() => {
                if(this.SumPercent  == 100 && newVal != oldVal){
                    this.udpatePercentageObjective()
                    this.ToastSuccess('success', 'Porcentagens atualizadas com sucesso!')
                    // this.SumTabIndex()
                } 
            }, 1500);
            

        },
    },
    mounted(){
        if(this.$store.state.portfolio.DataBase != null){
            this.$store.state.portfolio.DataBase.portfolio.class.sort((a,b) => {  
                return a.code.localeCompare(b.code);
            })
        }
    },
    methods:{
        async udpatePercentageObjective(){
            return new Promise(resolve => {
                for (let index = 0; index < this.$store.state.portfolio.DataBase.portfolio.stocks.length; index++) {
                    const element = this.$store.state.portfolio.DataBase.portfolio.stocks[index];
                    element.percentageObjective = this.Percentage(element.class, this.PercentageByClass(element.code)) 
                }
                this.Save()
                resolve(true)
            });
        },
        Save(){
            if(this.$store.state.portfolio.DataBase != null){
                this.$store.state.portfolio.DataBase.portfolio.class.sort((a,b) => {  
                    return a.code.localeCompare(b.code);
                })
            }
            firebase.firestore().collection('account')
                .doc(this.$store.state.user.CurrentUser.uid)
                .set(this.DataBase)
            .catch((error) => {
                let params = new URLSearchParams()
                params.append('email', this.$store.state.user.CurrentUser.email )
                params.append('event',  JSON.stringify(error) )
                this.$api.post("/Errorlog/Set", params)
                console.log(error);
            })
        },
        ToastSuccess(icon, title){
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: icon,
                title: title
            })
        },
    }
}
</script>

<style>
    .card-slider{
        background-color:#f8f9fa ;
    }
</style>