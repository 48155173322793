<template>
    <div v-if="DataBase != null" >
        <div class="card p-2">
            <b-tabs content-class="mt-3" v-model="tabIndex">
                <!-- CLASSE DE ATIVO -->
                <b-tab title="Classe de ativos" class="pl-4 pr-4" active>
                    <h6 class="text-center">Sua carteira de investimentos</h6>
                    <div class="progress progress-md mb-2">
                        <div class="progress-bar bg-teal" :style="'width:' + SumPercent + '%'"> {{SumPercent}}%</div>
                    </div>
                    <hr>
                    <div class="row card-slider m-2">
                        <div v-for="item in AllClassPortfolio" :key="item.className" class="col-xl-3 col-lg-4 col-md-6 p-2">
                            <Slider  :className="item.className" :SumPercent="SumPercent"  :SumTabIndex="SumTabIndex"  />
                        </div>
                    </div>
                    <br>
                </b-tab>
                <!-- CARTEIRA META -->
                <b-tab title="Carteira Meta" class="pl-2 pr-2" :disabled="SumPercent < 100">
                    <SliderClass/>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import Slider from "@/components/goal/Slider"
import SliderClass from "@/components/goal/SliderClass"

export default {
    components: { Slider, SliderClass },
    computed:{
        AllClassPortfolio(){
            return this.$store.state.global.AllClassPortfolio
        },
        DataBase(){
            if(this.$store.state.portfolio.DataBase != null){
                this.Clear()

                this.$store.state.portfolio.DataBase.portfolio.class.forEach(element => {
                    this.SumPercent += parseFloat(element.objectivePercentage)
                });
            }
            return this.$store.state.portfolio.DataBase
        },
        
    },
    data() {
        return {
            SumPercent: 0,
            tabIndex:0
        }
    },
    methods:{
        Clear(){
            this.SumPercent = 0
        },
        SumTabIndex(){
            this.tabIndex = 1
        }
    }

}
</script>

<style>
    .nav-tabs {
        margin: 0;
    }
    .nav-tabs .nav-link {
        padding: 10px;
        margin-left: 2px;
    }
</style>