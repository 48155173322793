<template>
    <tbody>
        <tr v-for="(itens, index) in DataBase.portfolio.stocks" :key="index">
            <td>{{ index + 1 }}</td>
            <td class="text-center">
                {{ itens.code }}
                <span
                    v-if="passedTopPrice(itens) && PremiumType > 1"
                    v-b-tooltip.hover
                    title="Cotação acima do preço teto, por isso esse ativo não entrará no cálculo de rebalanceamento."
                >
                    <font-awesome-icon icon="exclamation-circle" class="text-red" />
                </span>
            </td>
            <td class="text-center" v-html="$options.filters.TAG(itens.class)"></td>
            <td
                :class="'text-center ' + (passedTopPrice(itens) && PremiumType > 0 ? 'text-red' : '')"
            >
                <div v-if="itens.class != 'rf' && PremiumType > 1">
                    <div>{{ itens.topPriceBRL | BRL }}</div>
                    <div>{{ itens.topPriceUSD | USD }}</div>
                </div>
                <div v-else>-</div>
                <div v-if="PremiumType < 2">
                    <span
                        v-b-tooltip.hover
                        title="A funcionalidade preço teto só está disponível para usuários do nível profissional"
                    >
                        <font-awesome-icon icon="exclamation-circle" class="text-red" />
                    </span>
                </div>
            </td>
            <td class="text-center price">
                <div v-if="itens.priceBRL < 0.01">{{ itens.priceBRL | BRL_C }}</div>
                <div v-else>{{ itens.priceBRL | BRL }}</div>

                <div v-if="itens.priceUSD < 0.01">{{ itens.priceUSD | USD_C }}</div>
                <div v-else>{{ itens.priceUSD | USD }}</div>
            </td>
            <td class="text-center">{{ itens.quantity | QTD }}</td>
            <td class="text-center">
                <div>{{ (itens.priceBRL * itens.quantity) | BRL }}</div>
                <div>{{ (itens.priceUSD * itens.quantity) | USD }}</div>
            </td>
            <td class="text-center">{{ CurrentPercentage(itens.code) | % }}</td>
            <td class="text-center">{{ itens.percentageObjective | % }}</td>
            <td
                class="text-center"
            >{{ (CurrentPercentage(itens.code) - itens.percentageObjective) | % }}</td>
            <td
                :class="'text-center ' + (FilterPortfolioCalc(itens.code).length > 0 && FilterPortfolioCalc(itens.code)[0].quantityBuy > 0 ? ' bold' : '')"
            >
                {{
                    (
                        FilterPortfolioCalc(itens.code).length > 0 ?
                            FilterPortfolioCalc(itens.code)[0].quantityBuy :
                            0
                    )
                }}
            </td>
            <td
                :class="'text-center ' + (FilterPortfolioCalc(itens.code).length > 0 && FilterPortfolioCalc(itens.code)[0].quantityBuy > 0 ? ' bold' : '')"
            >
                <div>
                    {{
                        (
                            FilterPortfolioCalc(itens.code).length > 0 ?
                                FilterPortfolioCalc(itens.code)[0].quantityBuy :
                                0
                        ) * itens.priceBRL | BRL
                    }}
                </div>
                <div>
                    {{
                        (
                            FilterPortfolioCalc(itens.code).length > 0 ?
                                FilterPortfolioCalc(itens.code)[0].quantityBuy :
                                0
                        ) * itens.priceUSD | USD
                    }}
                </div>
            </td>
            <td class="text-center">
                <span>
                    <label class="custom-switch">
                        <input
                            type="checkbox"
                            :disabled="passedTopPrice(itens)"
                            name="custom-switch-checkbox"
                            class="custom-switch-input"
                            v-model="itens.isBuy"
                            @change="Save(itens.code)"
                        />
                        <span class="custom-switch-indicator"></span>
                    </label>
                </span>
            </td>
            <td class="text-center">
                <span v-b-tooltip.hover title="Lançar as operações de entrada e saida.">
                    <button
                        type="button"
                        class="btn btn-secondary btn-sm"
                        @click="Modal(itens.code)"
                    >
                        <font-awesome-icon icon="exchange-alt" />
                    </button>
                </span>
                <span v-b-tooltip.hover title="Lança a quantidade sugerida pela plataforma">
                    <button
                        type="button"
                        :class="'btn btn-' + BuySuggestionColor(itens.code) + ' btn-sm ml-1'"
                        @click="BuySuggestion(itens)"
                    >
                        <font-awesome-icon icon="cart-plus" />
                    </button>
                </span>
            </td>
        </tr>
    </tbody>
</template>

<script>
import Swal from 'sweetalert2'
import firebase from "firebase/app"
import { mapGetters, mapActions } from 'vuex'
export default {
    props: {
        DataBase: { type: Object, required: true },
        // ValueBuy: { type: Number, required: true },
        PortfolioCalc: { required: true },
        Modal: { type: Function }
    },
    computed: {
        ...mapGetters('portfolio', ['CurrentPercentage', 'GetDecimalPlaces']),
        PremiumType() {
            return this.$store.state.portfolio.DataBase.settings.premium.type
        },
        ValueBuy: {
            get: function () { return this.$store.state.portfolio.ValueBuy },
            set: function (newValue) { this.$store.state.portfolio.ValueBuy = newValue }
        },
    },
    mounted() {
        if (this.PremiumType < 1) {
            for (let index = 0; index < this.$store.state.portfolio.DataBase.portfolio.stocks.length; index++) {
                const element = this.$store.state.portfolio.DataBase.portfolio.stocks[index];
                element.isBuy = true
            }
        }

        for (let index = 0; index < this.$store.state.portfolio.DataBase.portfolio.stocks.length; index++) {
            const element = this.$store.state.portfolio.DataBase.portfolio.stocks[index];
            if (this.passedTopPrice(element)) element.isBuy = false
        }

        if (this.ValueBuy != 1000) this.ValueBuy = 1000
    },
    methods: {
        ...mapActions('global', ['MsgNotPermission']),
        FilterPortfolioCalc(code) {
            return this.PortfolioCalc.filter(item => item.code == code)
        },
        passedTopPrice(row) {
            if (row.class == 'rf') return false
            const CoinTop = (this.DataBase.settings.usdCoin ? 'topPriceUSD' : 'topPriceBRL')
            const Coin = (this.DataBase.settings.usdCoin ? 'priceUSD' : 'priceBRL')

            //if(row.code == 'PLD')
            //console.log(parseFloat(row[Coin]), parseFloat(row[CoinTop]), Coin, CoinTop, parseFloat(row[Coin]) > parseFloat(row[CoinTop]))

            if (parseFloat(row[CoinTop]) > 0 && parseFloat(row[Coin]) > parseFloat(row[CoinTop])) return true
            return false
        },
        BuySuggestion(row) {
            if (this.PortfolioCalc.filter(item => item.code == row.code).length == 0) return
            if (this.PortfolioCalc.filter(item => item.code == row.code)[0].quantityBuy <= 0) return
            let DecimalPlaces = this.GetDecimalPlaces(row.class);

            row.quantity = parseFloat(row.quantity) + parseFloat(this.PortfolioCalc.filter(item => item.code == row.code)[0].quantityBuy)
            row.quantity = parseFloat(row.quantity).toFixed(DecimalPlaces)

            const Coin = (this.DataBase.settings.usdCoin ? 'priceUSD' : 'priceBRL')
            this.ValueBuy = parseFloat(this.ValueBuy - parseFloat(this.PortfolioCalc.filter(item => item.code == row.code)[0].quantityBuy * this.PortfolioCalc.filter(item => item.code == row.code)[0][Coin])).toFixed(2)

            this.PortfolioCalc.filter(item => item.code == row.code)[0].quantityBuy = 0
            firebase.firestore().collection('account')
                .doc(this.$store.state.user.CurrentUser.uid)
                .set(this.DataBase)
                .catch((error) => {
                    let params = new URLSearchParams()
                    params.append('email', this.$store.state.user.CurrentUser.email)
                    params.append('event', JSON.stringify(error))
                    this.$api.post("/Errorlog/Set", params)
                    console.log(error);
                })

            this.ToastSuccess('success', "Lançamento realizado com sucesso!")
        },
        BuySuggestionColor(code) {
            if (this.PortfolioCalc.filter(item => item.code == code).length == 0) return 'red disabled'
            if (this.PortfolioCalc.filter(item => item.code == code)[0].quantityBuy > 0) return 'green'
            return 'red disabled'
        },
        Save(code) {
            if (this.PremiumType < 1) {
                this.MsgNotPermission('Essa funcionalidade só está disponível para usuários Premium<br>Clique aqui e <a href="pricing"> <b> Seja Premium </b></a>')
                setTimeout(() => {
                    this.$store.state.portfolio.DataBase.portfolio.stocks.filter(item => item.code == code)[0].isBuy = 1
                }, 100);
                return
            }

            firebase.firestore().collection('account')
                .doc(this.$store.state.user.CurrentUser.uid)
                .set(this.DataBase)
                .catch((error) => {
                    let params = new URLSearchParams()
                    params.append('email', this.$store.state.user.CurrentUser.email)
                    params.append('event', JSON.stringify(error))
                    this.$api.post("/Errorlog/Set", params)
                    console.log(error);
                })
        },
        ToastSuccess(icon, title) {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 700,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: icon,
                title: title
            })
        },
    }

}
</script>

<style>
.custom-switch {
    padding-left: 0px;
}
.bold {
    font-weight: bold;
}
</style>