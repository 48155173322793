<template>
    <thead>
        <tr>
            <th class="">#</th>
            <th v-for="(head, index)  in header" :key="index" class="text-dark text-center" style="cursor: pointer;" v-b-tooltip.hover :title="head.tooltip" @click="OrderBy(head.code)"> 
                <span v-if="head.code == 'isBuy'">
                    <!-- <label class="custom-checkbox mr-4 pt-0 mt-0 mb-3">
                        <input type="checkbox" class="custom-control-input" name="example-checkbox1" v-model="select" checked="">
                        <span class="custom-control-label"></span>
                    </label> -->
                    <span>
                        <label class="custom-checkbox mr-1 pt-0 mt-0 mb-0 ml-2"  >
                            <input type="checkbox" name="custom-switch-checkbox" class="custom-switch-input" v-model="select" >
                            <span class="custom-switch-indicator"></span>
                        </label>
                    </span>
                </span>
                <b>{{head.title}}</b>&nbsp;
                <font-awesome-icon class="text-teal" :icon="head.icon"/>
            </th>
            <th class="text-dark text-center" v-b-tooltip.hover.left title="Lançar as operações de entrada e saida. Para lançar a nossa sugestão clique no botão verde" >
                <b>OP</b>
            </th>
        </tr>
    </thead>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        DataBase: { type: Object, required: true },
        CallOrderByIni: { type: Date, required: true },
    },
    data() {
        return {
            header:[ 
                { code:'code', icon:'arrows-alt-v', title:'Código', tooltip:"Código de negociação do ativo." },
                { code:'class', icon:'arrows-alt-v', title:'Classe' , tooltip:"Classe / Categoria do Ativo."},
                { code:'topPriceBRL', icon:'arrows-alt-v', title:'Preço Teto', tooltip:"Preço máximo que deseja pagar de um ativo, caso o valor esteja zerado esse ativo vai entrar no rebalanceamento."},
                { code:'priceBRL', icon:'arrows-alt-v', title:'Cotação', tooltip:"Valor das cotações são referentes ao ultimo fechamento da B3."},
                { code:'quantity', icon:'arrows-alt-v', title:'Qtd', tooltip:"Quantidade que você tem em custódia."},
                { code:'sumValue', icon:'arrows-alt-v', title:'Vlr Inv.', tooltip:"Valor total investido nesse ativo. Fórmula: Qtd x Cotação."},
                { code:'percentageCurrent', icon:'arrows-alt-v', title:'% Atual', tooltip:"Porcentagem atual alocado nesse ativo."},
                { code:'percentageObjective', icon:'arrows-alt-v', title:'% Obj', tooltip:"Porcentagem desejada, definida na carteira meta."},
                { code:'difference', icon:'arrows-alt-v', title:'% Dif', tooltip:"Diferença entre a porcenteagem atual e objetivo. Fórmula: % Atual - % Objetivo."},
                { code:'quantityBuy', icon:'arrows-alt-v', title:'Qtd Apt', tooltip:"Quantidade que deve ser aportada nesse ativo."},
                { code:'priceBuy', icon:'arrows-alt-v', title:'Vlr Apt', tooltip:"Valor que deve ser aportado."},
                { code:'isBuy', icon:'arrows-alt-v', title:'Apt?', tooltip:"O sistema só vai realizar o rebalanceamento dos ativos que estiverem checados."},
            ],
            select:true
        }
    },
    computed:{
        ...mapGetters('portfolio', ['CurrentPercentage']),
        PortfolioCalc(){
            return this.$store.state.portfolio.PortfolioCalc
        }
    },
    watch: {
        CallOrderByIni() {
            this.OrderByIni()
        },
        select(newVal){
            this.DataBase.portfolio.stocks.forEach(element => {
                element.isBuy = newVal
            });
            
        }
    },
    methods:{
        OrderByIni(){
            this.header.filter(element => element.code == 'priceBuy')[0].icon ='arrows-alt-v'
            this.OrderBy('priceBuy')
        },
        OrderBy(Col){
            let OrderDesc = ( this.header.filter(element =>  element.code == Col)[0].icon == 'long-arrow-alt-up' ) ? false : true;

            for (let index = 0; index < this.header.length; index++) {
                this.header[index].icon = 'arrows-alt-v'
            }
            
            this.header.filter(element =>  element.code == Col)[0].icon = OrderDesc ? 'long-arrow-alt-up'  : 'long-arrow-alt-down'
            this.DataBase.portfolio.stocks.sort((a,b) => {

                if(OrderDesc){
                    let NewA = b;
                    let NewB = a;
                    b = NewB;
                    a = NewA;
                }

                if(Col == 'sumValue' || Col == 'percentageCurrent' ) 
                    return (a.priceBRL * a.quantity) - (b.priceBRL * b.quantity)

                if(Col == 'difference')
                    return ( this.CurrentPercentage(a.code) - a.percentageObjective ) - ( this.CurrentPercentage(b.code) - b.percentageObjective )
 
                if(Col ==  'priceBuy')
                    return (
                        (
                            this.PortfolioCalc.filter(item => item.code == a.code).length > 0 ? 
                            (this.PortfolioCalc.filter(item => item.code == a.code)[0].quantityBuy * a.priceBRL ): 
                            0
                        ) -
                        (
                            this.PortfolioCalc.filter(item => item.code == b.code).length > 0 ? 
                            (this.PortfolioCalc.filter(item => item.code == b.code)[0].quantityBuy * b.priceBRL ) : 
                            0
                        ) 
                    )

                if(Col ==  'quantityBuy')
                    return (
                        (
                            this.PortfolioCalc.filter(item => item.code == a.code).length > 0 ? 
                            this.PortfolioCalc.filter(item => item.code == a.code)[0].quantityBuy : 
                            0
                        ) -
                        (
                            this.PortfolioCalc.filter(item => item.code == b.code).length > 0 ? 
                            this.PortfolioCalc.filter(item => item.code == b.code)[0].quantityBuy : 
                            0
                        ) 
                    )

                if(typeof a[Col] === "number" || Math.floor(a[Col]) === a[Col] || isFinite(a[Col]))
                    return a[Col] - b[Col];
                else
                    return a[Col].localeCompare(b[Col]);
            })
        }
    }
}
</script>

<style>

</style>