<template>
    <div>
        <div class="card mb-1" v-for="(itens, index) in DataBase" :key="index">
            <div :class="'card-status card-status-left bg-' + $options.filters.COLOR(itens.class) + ' br-bl-7 br-tl-7'"></div>
            
            <div class="card-body pl-4 pr-4 pt-1 pb-1 ">
                <div class="row">

                    <!-- HEADER -->
                    <div class="col-6 border-bottom p-2" style="font-size:medium;">
                        <b>{{itens.code}}</b>
                    </div>
                    <div class="col-6 text-right border-bottom p-1" style="font-size:medium;">
                        <span class="mr-2" v-html="$options.filters.TAG(itens.class)"></span>
                        <span @click="Edit(itens.code)">
                            <font-awesome-icon style="cursor: pointer;" icon="edit" class="text-blue"/>
                        </span> | 
                        <span @click="Delete(itens.code)">
                            <font-awesome-icon style="cursor: pointer;" icon="trash-alt" class="text-red"/>
                        </span> 
                    </div>

                    <!-- Notas -->
                    <div class="col-12 text-center border-bottom p-1"  style="font-size:small;">
                        Nota: {{itens.note}}
                    </div>

                    <!-- Preço Teto -->
                    <div class="col-12 text-center border-bottom p-1"  style="font-size:small;">
                        Preço Teto:
                        <span v-if="PremiumType < 2">
                            <span v-b-tooltip.hover title="A funcionalidade preço teto só está disponível para usuários do nível profissional">
                                <font-awesome-icon icon="exclamation-circle" class="text-red"/>
                            </span>
                        </span>
                        <span v-else>
                            {{itens.topPriceBRL  | BRL}}  | {{itens.topPriceUSD  | USD}}
                        </span>
                    </div>

                    <!-- Quantidade -->
                    <div class="col-12 text-center border-bottom p-1">
                        Quantidade:
                        {{itens.quantity}}
                    </div>
                    
                    <!-- Quantidade -->
                    <div class="col-6 text-center border-right p-1">
                        % Classe:
                        {{ PercentageByClass(itens.code) | % }}
                    </div>
                    <div class="col-6 text-center p-1">
                        % Carteira: 
                        {{ Percentage(itens.class, PercentageByClass(itens.code)) | % }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        ClassStock: { type: String, required: true },
        Delete: { type: Function, required: true },
        Edit: { type: Function, required: true },
    },
    computed:{
        ...mapGetters('portfolio', ['Percentage','PercentageByClass']),
        DataBase(){
            let NewDataBase = null
            if(this.$store.state.portfolio.DataBase != null){
                NewDataBase = this.$store.state.portfolio.DataBase.portfolio.stocks.filter(item => item.class == this.ClassStock)
            }
            return NewDataBase
        },
        PremiumType(){
            return this.$store.state.portfolio.DataBase.settings.premium.type
        },
    },

}
</script>

<style scoped>
    .card-body{
        font-size: x-small;
    }
</style>