<template>
    <div>
        <div class="card mb-1" v-for="(itens, index) in DataBase.portfolio.stocks" :key="index">
            <div :class="'card-status card-status-left bg-' + $options.filters.COLOR(itens.class) + ' br-bl-7 br-tl-7'"></div>
         
            <div class="card-body pl-4 pr-4 pt-1 pb-1 ">
                <div class="row">
                    
                    <!-- HEADER -->
                    <div class="col-10 border-bottom p-2" style="font-size:medium;">
                        <span v-if="passedTopPrice(itens) && PremiumType > 1" class="mr-2" v-b-tooltip.hover title="Cotação acima do preço teto, por isso esse ativo não entrará no cálculo de rebalanceamento.">
                            <font-awesome-icon icon="exclamation-circle" class="text-red"/>
                        </span>
                        <b>{{itens.code}}</b>
                        <span class="pl-2 pr-2" v-html="$options.filters.TAG(itens.class)"></span>
                    </div>
                    <div class="col-2 border-bottom p-1" style="font-size:medium;">
                        <div class=" text-right">

                            <label class="custom-switch m-0">
                                <input type="checkbox" value="1" class="custom-switch-input" :disabled="passedTopPrice(itens)"  v-model="itens.isBuy"  @change="Save(itens.code, true)">
                                <span class="custom-switch-indicator"></span>
                            </label>
                        </div>
                    </div>
                
                    <!-- INFORMAÇÃO INICIAL -->
                    <b class="col-5 pt-2 text-right" style="font-size:small;">
                        Cotação:
                    </b>
                    <div class="col-5 border-right" style="font-size:small;">
                        <div> 
                            {{itens.priceBRL  | BRL}}
                        </div>
                        <div>
                            {{itens.priceUSD  | USD}}
                        </div> 
                    </div>
                    <div class="col-2 pb-2">
                        <b-dropdown id="dropdown-1" dropleft  size="sm" text="" class="m-md-2 mt-1">
                            <template #button-content>
                                <font-awesome-icon  icon="bars" />
                            </template>
                            <b-dropdown-form>
                                <label class="custom-switch m-0">
                                    <input type="checkbox" value="1" class="custom-switch-input" v-model="DataBase.settings.mobile.porcentagem"  @change="Save('',false)">
                                    <span class="custom-switch-indicator"></span>
                                    <span class="pl-1">Porcentagem</span>
                                </label>
                            </b-dropdown-form>
                            <b-dropdown-form>
                                <label class="custom-switch m-0">
                                    <input type="checkbox" value="1" class="custom-switch-input"  v-model="DataBase.settings.mobile.amount"  @change="Save('',false)">
                                    <span class="custom-switch-indicator"></span>
                                    <span class="pl-1">Vlr Inv.</span>
                                </label>
                            </b-dropdown-form>
                            <b-dropdown-form>
                                <label class="custom-switch m-0">
                                    <input type="checkbox" value="1" class="custom-switch-input"  v-model="DataBase.settings.mobile.topPrice"  @change="Save('',false)">
                                    <span class="custom-switch-indicator"></span>
                                    <span class="pl-1">Preço Teto</span>
                                </label>
                            </b-dropdown-form>
                            <!-- <b-dropdown-divider></b-dropdown-divider> -->
                            <!-- <b-dropdown-item @click="Modal(itens.code)" > <font-awesome-icon icon="exchange-alt"/> Operações</b-dropdown-item> -->
                            <!-- <b-dropdown-item @click="BuySuggestion(itens)" > 
                                <font-awesome-icon icon="cart-plus"/>  Lança sugestão
                            </b-dropdown-item> -->
                        </b-dropdown>
                    </div>
                </div> 

                <!-- VALOR A SER APORTADO -->
                <div :class="'row text-center ' + (ColorRow(itens.code) > 0 ? 'text-gray-dark ':'')">
                    <div class="col-12 border-top  pt-1" style="font-size:small;">
                        <b>Quantidade do Aporte: </b>
                        {{ 
                            (
                                PortfolioCalc.filter(item => item.code == itens.code).length > 0 ? 
                                PortfolioCalc.filter(item => item.code == itens.code)[0].quantityBuy : 
                                0
                            ) 
                        }} 
                    </div>
                    
                    <div class="col-12 pb-1" style="font-size:small;">
                        <b>Valor: </b>
                        {{ 
                            (
                                PortfolioCalc.filter(item => item.code == itens.code).length > 0 ? 
                                PortfolioCalc.filter(item => item.code == itens.code)[0].quantityBuy : 
                                0
                            ) * itens.priceBRL | BRL
                        }} 
                        |
                        {{ 
                            (
                                PortfolioCalc.filter(item => item.code == itens.code).length > 0 ? 
                                PortfolioCalc.filter(item => item.code == itens.code)[0].quantityBuy : 
                                0
                            ) * itens.priceUSD | USD
                        }} 
                    </div> 
                </div>

                <!-- VALOR QUE TENHO EM CARTEIRA -->
                <div v-if="DataBase.settings.mobile.amount" class="row text-center">
                    <div class="col-12 border-top pt-1" style="font-size:small;">
                        <b>Quantidade em custódia: </b> {{itens.quantity | QTD }}
                    </div>
                    <div class="col-12" style="font-size:small;">
                        <b>Vlr Inv.:</b> {{( itens.priceBRL * itens.quantity) | BRL}} | {{( itens.priceUSD * itens.quantity) | USD}}
                    </div>
                </div>
               
                <!-- PREÇO TETO -->
                <div v-if="DataBase.settings.mobile.topPrice && itens.class != 'rf'" class="row text-center"  >
                    <div class="col-12 border-top pt-1 pb-1">
                        <b>Preço Teto: </b> 
                        <span v-if="PremiumType < 2">
                            <span v-b-tooltip.hover title="A funcionalidade preço teto só está disponível para usuários do nível profissional">
                                <font-awesome-icon icon="exclamation-circle" class="text-red"/>
                            </span>
                        </span>
                        <span v-else :class="(passedTopPrice(itens) && PremiumType > 0 ? 'text-red' : '')">
                            {{itens.topPriceBRL  | BRL}}  | {{itens.topPriceUSD  | USD}}
                        </span>
                    </div>
                </div>

                <!-- PORCENTAGEM -->
                <div v-if="DataBase.settings.mobile.porcentagem" class="row text-center"  >
                    <div class="col-4 border-top pt-1 pb-1">
                        <b>% Atual: </b> {{ CurrentPercentage(itens.code) | % }}
                    </div>
                    <div class="col-4 border-top pt-1 pb-1">
                        <b>% Obj: </b> {{ itens.percentageObjective | % }}
                    </div>
                    <div class="col-4 border-top pt-1 pb-1">
                        <b>% Dif: </b>  {{ (CurrentPercentage(itens.code) - itens.percentageObjective) | % }}
                    </div>
                </div>

                <div class="row text-center  border-top" >
                    <div class="col-6 mb-1 mt-1 border-right">
                        <span @click="Modal(itens.code)" class="btn btn-primary btn-block btn-sm">
                            <font-awesome-icon icon="exchange-alt"/> Operações
                        </span>
                    </div>
                    <div class="col-6 mt-1">
                        <button @click="BuySuggestion(itens)"  :class="'btn btn-' + BuySuggestionColor(itens.code) + ' btn-block btn-sm'">
                            <font-awesome-icon icon="cart-plus"/>  Lança sugestão
                        </button>
                    </div>
                </div>

                <!-- <div class="row text-center  border-top" >
                    <div class="col-6 mb-1 mt-1 border-right">
                        <span @click="Modal(itens.code)" class="btn btn-primary btn-icon btn-sm">
                            <font-awesome-icon icon="exchange-alt"/> 
                        </span>
                    </div>
                    <div class="col-6 mt-1">
                        <button @click="BuySuggestion(itens)"  :class="'btn btn-' + BuySuggestionColor(itens.code) + ' btn-icon btn-sm'">
                            <font-awesome-icon icon="cart-plus"/>  
                        </button>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import firebase from "firebase/app"
import { mapGetters, mapActions } from 'vuex'
export default {
    props: {
        PortfolioCalc: { required: true },
        CallOrderByIni: { type: Date, required: true },
        Modal:{type: Function}
    },
    computed:{
        ...mapGetters('portfolio', ['CurrentPercentage','GetDecimalPlaces']),
        DataBase(){
            return this.$store.state.portfolio.DataBase
        },
        PremiumType(){
            return this.$store.state.portfolio.DataBase.settings.premium.type
        },
    },
    watch: {
        CallOrderByIni() {
            this.OrderByIni()
        }
    },
    mounted(){
        if(this.PremiumType < 1){
            for (let index = 0; index < this.$store.state.portfolio.DataBase.portfolio.stocks.length; index++) {
                const element = this.$store.state.portfolio.DataBase.portfolio.stocks[index];
                element.isBuy = true
            }
        }
    },
    methods:{
        ...mapActions('global', ['MsgNotPermission']),
        ColorRow(code){
            return this.PortfolioCalc.filter(item => item.code == code).length > 0 ?  this.PortfolioCalc.filter(item => item.code == code)[0].quantityBuy : 0
        },
        Save(code, valid){
            if(this.PremiumType < 1 && valid){
                this.MsgNotPermission('Essa funcionalidade só está disponível para usuários Premium<br>Clique aqui e <a href="pricing"> <b> Seja Premium </b></a>')
                setTimeout(() => {
                    this.$store.state.portfolio.DataBase.portfolio.stocks.filter(item => item.code == code)[0].isBuy = true
                }, 100);
                return
            }
            setTimeout(() => {
                firebase.firestore().collection('account')
                    .doc(this.$store.state.user.CurrentUser.uid)
                    .set(this.DataBase)
            }, 10);
        },
        passedTopPrice(row){
            if(row.class == 'rf') return false
            const CoinTop = (this.DataBase.settings.usdCoin ? 'topPriceUSD' : 'topPriceBRL') 
            const Coin = (this.DataBase.settings.usdCoin ? 'priceUSD' : 'priceBRL') 
            if(parseFloat(row[CoinTop]) > 0 && parseFloat(row[Coin]) > parseFloat(row[CoinTop])) return true
            return false
        },
        BuySuggestion(row){
            if(this.PortfolioCalc.filter(item => item.code == row.code).length == 0) return
            if(this.PortfolioCalc.filter(item => item.code == row.code)[0].quantityBuy <= 0) return
            let DecimalPlaces = this.GetDecimalPlaces(row.class);

            row.quantity = parseFloat(row.quantity) + parseFloat(this.PortfolioCalc.filter(item => item.code == row.code)[0].quantityBuy)
            row.quantity = parseFloat(row.quantity).toFixed(DecimalPlaces)
            this.PortfolioCalc.filter(item => item.code == row.code)[0].quantityBuy = 0
            firebase.firestore().collection('account')
                .doc(this.$store.state.user.CurrentUser.uid)
                .set(this.DataBase)
            .catch((error) => {
                let params = new URLSearchParams()
                params.append('email', this.$store.state.user.CurrentUser.email )
                params.append('event',  JSON.stringify(error) )
                this.$api.post("/Errorlog/Set", params)
                console.log(error);
            })
             
            this.ToastSuccess('success', "Lançamento realizado com sucesso!")
        },
        BuySuggestionColor(code){
            if(this.PortfolioCalc.filter(item => item.code == code).length == 0) return 'red disabled'
            if(this.PortfolioCalc.filter(item => item.code == code)[0].quantityBuy > 0) return 'green'
            return 'red disabled'
        },
        OrderByIni(){
            this.DataBase.portfolio.stocks.sort((a,b) => {
                return (
                    (
                        this.PortfolioCalc.filter(item => item.code == b.code).length > 0 ? 
                        (this.PortfolioCalc.filter(item => item.code == b.code)[0].quantityBuy * b.priceBRL ): 
                        0
                    ) -
                    (
                        this.PortfolioCalc.filter(item => item.code == a.code).length > 0 ? 
                        (this.PortfolioCalc.filter(item => item.code == a.code)[0].quantityBuy * a.priceBRL ) : 
                        0
                    ) 
                )
            })
        },
        ToastSuccess(icon, title){
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: icon,
                title: title
            })
        },
    }

}
</script>

<style scoped>
    .card-header {
        min-height: 0px;
    }
    .card-body{
        font-size: x-small;
    }
    p{
        line-height: 1px;
    }
</style>